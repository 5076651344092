import React, { useState } from 'react'
import static_01 from '../../assets/SocialApps/static_01.png'
import static_02 from '../../assets/SocialApps/static_02.png'
import genuin_logo from '../../assets/SocialApps/genuin_logo.png'
import ig_logo from '../../assets/SocialApps/ig_logo.png'
import f1 from '../../assets/SocialApps/f1.svg'
import f2 from '../../assets/SocialApps/f2.svg'
import f3 from '../../assets/SocialApps/f3.svg'
import Modal from './Modal'

const SocialApps = ({ windowWidth }) => {
    const [selectedButton, setSelectedButton] = useState('Genuin');
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleScreenButtonClick = (buttonName) => {
        setSelectedButton(buttonName);
    };

    const adItemStyle = {
        padding: windowWidth > 768 ? '8px 16px' : '4px 12px',
        height: '32px',
        width: '100%',
        borderRadius: '10px',
        backgroundColor: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: 0,
        cursor: 'pointer',
    };

    const AdButton = ({ label, selectedButton, onClick, imgSrc }) => (
        <div
            style={{
                ...adItemStyle,
                backgroundColor: selectedButton === label ? 'rgba(179, 221, 226, 0.3)' : '#F3F3F3',
                border: selectedButton === label ? '1px solid #B3DDE2' : 0
            }}
            onClick={() => onClick(label)}
        >
            {<img src={imgSrc} style={{ height: '32px', marginRight: '10px' }} alt="Your Image" className="genuin-mockup-img" />}
            {label}
        </div>
    );

    const actionButtonStyle = {
        padding: windowWidth > 768 ? '16px 24px' : '9px 14px',
        height: '32px',
        width: 'auto',
        borderRadius: '50px',
        backgroundColor: '#B3DDE2E5',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: 0,
        marginRight: '10px'
        // cursor: 'pointer',
    };

    const ActionButton = ({ label, selectedButton, onClick, imgSrc }) => (
        <div
            style={{
                ...actionButtonStyle,
            }}
        >
            <img src={imgSrc} style={{ height: '32px', marginRight: '10px' }} alt="Your Image" />
            {label}
        </div>
    );

    return (
        <div>
            <div className='SocialApps-container container'>
                <div className='SocialApps-image-comp' >
                    <div className='SocialApps-buttons'>
                        <AdButton label="Genuin" selectedButton={selectedButton} onClick={handleScreenButtonClick} imgSrc={genuin_logo} />
                        <AdButton label="Instagram" selectedButton={selectedButton} onClick={handleScreenButtonClick} imgSrc={ig_logo} />
                    </div>
                    {selectedButton === 'Genuin' ?
                        <img
                            src={static_01}
                            alt="static_01"
                            style={{
                                height: windowWidth > 768 ? '500px' : '300px'
                            }}
                        /> :
                        <img
                            src={static_02}
                            alt="static_02"
                            style={{
                                height: windowWidth > 768 ? '500px' : '300px'
                            }}
                        />}

                </div>
                <div className='SocialApps-text-comp'>
                    <p className='SocialApps-title'>
                    Build Using a Recognized Consumer Experience
                    </p>
                    <p className='SocialApps-description'>
                    By integrating social elements from Instagram, TikTok, Facebook, YouTube, and Genuin, we enable users to connect through a familiar social landscape.
                    </p>
                    {windowWidth < 768 && <button type="submit" className="Ad-button" style={{ textDecoration: 'none' }} onClick={() => setIsModalOpen(true)}>
                        Know More
                    </button>}
                    <div style={{
                        display: 'flex'
                    }}>
                    <ActionButton label="Swipe" imgSrc={f1}/>
                    <ActionButton label="Tap" imgSrc={f2}/>
                    <ActionButton label="Engage" imgSrc={f3}/>
                    </div>
                    {windowWidth > 768 && <button type="submit" className="Ad-button" style={{ textDecoration: 'none', width: '160px' }} onClick={() => setIsModalOpen(true)}>
                        Learn More
                    </button>}

                    <Modal
                        windowWidth={windowWidth}
                        isModalOpen={isModalOpen}
                        closeModal={() => setIsModalOpen(false)}
                    />
                </div>
            </div>
        </div>
    )
}

export default SocialApps