import React, { useState } from 'react';
import { apiurl } from '../../config';
import TextField from '@mui/material/TextField';
import insta from '../../assets/Form/instagram.svg';
import tiktok from '../../assets/Form/tiktok.svg';


const FormSection = ({ windowWidth }) => {

    const initialFormData = {
        firstName: '',
        lastName: '',
        email: '',
        mobile: '',
        igusername: '',
        tiktokusername: '',
      };

    const [formData, setFormData] = useState(initialFormData);
    const [approvalMessage, setApprovalMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isSent, setIsSent] = useState(false);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setApprovalMessage('');

        // console.log(formData);

        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (!emailPattern.test(formData.email)) {
            setApprovalMessage('Enter a valid email address');
            return;
        }

        // if (!formData.igusername && !formData.tiktokusername) {
        //     setApprovalMessage('Please enter at least one username');
        //     return;
        // }

        let socialMediaData = {};

        if (formData.igusername) {
            socialMediaData.instagram = formData.igusername;
        }
        if (formData.tiktokusername) {
            socialMediaData.tiktok = formData.tiktokusername;
        }

        const formattedMobile = '+1' + formData.mobile;

        const formmetedData = {
            email: formData.email,
            name: `${formData.firstName} ${formData.lastName}`,
            mobile: formattedMobile,
            ...socialMediaData
        };

        // console.log(formmetedData);

        const apiUrl = `${apiurl}/api/v3/public/brand_integration`;

        setIsLoading(true);
        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formmetedData),
            });

            console.log(formData);

            if (response.status === 200) {
                setIsSent(true)
                setApprovalMessage('Thank You For Your Response!');
                setIsLoading(false);
                setFormData(initialFormData);

                window.open(
                    `https://meetings-eu1.hubspot.com/joshua-winograd?embed=true&email=${formData.email}&firstName=${formData.firstName}&lastName=${formData.lastName}`,
                    '_blank'
                  );
            } else {
                // console.error(response);
                setApprovalMessage('Something went wrong; please try again later.');
                setIsLoading(false);
            }

            setTimeout(() => {
                setApprovalMessage('');
            }, 5000);
        } catch (error) {
            console.error('Error while sending data:', error);
            setIsLoading(false);
        }
    };

    return (
        <div className="Form-container">
            <div className="Form-box-text">
                <p className="Form-text-comp">
                    <span className="Form-text-1">See It In Action</span><br /><br /><span className="Form-text-2">Connect with us for a demo - complete the form below, and our experts will reach out to get you started.</span>
                </p>
            </div>
            <div className='Form-box-image'>
                <div className='container-form'>
                    <form onSubmit={handleSubmit} className='form'>
                        <div style={{
                            display: 'flex',
                            width: '100%'
                        }}>
                            <TextField
                                id="outlined-basic"
                                label="First Name"
                                variant="outlined"
                                fullWidth
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleChange}
                                margin="normal"
                                className="Modal-text-field"

                                required
                                style={{
                                    marginRight: '10px'
                                }}
                            />
                            <TextField
                                id="outlined-basic"
                                label="Last Name"
                                variant="outlined"
                                fullWidth
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleChange}
                                margin="normal"
                                className="Modal-text-field"

                                required
                            />
                        </div>
                        <TextField
                            id="outlined-basic"
                            label="Company Email"
                            variant="outlined"
                            fullWidth
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            margin="normal"
                            className='text-field'
                            required
                        />
                        <TextField
                            id="outlined-basic"
                            label="Phone Number"
                            variant="outlined"
                            fullWidth
                            name="mobile"
                            value={formData.mobile}
                            onChange={handleChange}
                            margin="normal"
                            className='text-field'
                            required
                        />
                        {/* <div className='select-container'>
                            {windowWidth > 768 ? (
                                <div className='select-label'>
                                    <img src={insta} style={{ height: '28px', marginRight: '10px' }} />Instagram
                                </div>
                            ) : (
                                <div className='select-label'>
                                    <img src={insta} style={{ height: '36px' }} />
                                </div>
                            )}
                            <div className='username-field'>
                                <TextField
                                    id="outlined-basic"
                                    label="Enter Username"
                                    variant="outlined"
                                    fullWidth
                                    name="igusername"
                                    value={formData.igusername}
                                    onChange={handleChange}
                                    margin="normal"
                                />
                            </div>
                        </div> */}
                        {/* <div className='select-container'>
                            {windowWidth > 768 ?
                                (
                                    <div className='select-label' style={{ opacity: '30%' }}>
                                        <img src={tiktok} style={{ height: '28px', marginRight: '10px' }} />TikTok
                                    </div>
                                ) : (
                                    <div className='select-label'>
                                        <img src={tiktok} style={{ height: '36px', opacity: '30%' }} />
                                    </div>
                                )}
                            <div className='username-field'>
                                <TextField
                                    id="outlined-basic"
                                    label="Enter Username"
                                    variant="outlined"
                                    fullWidth
                                    name="tiktokusername"
                                    value={formData.tiktokusername}
                                    onChange={handleChange}
                                    margin="normal"
                                    disabled={true}
                                />
                            </div>
                        </div> */}
                        <button className='Form-submit-button' type="submit" disabled={isLoading}>
                            {isLoading ? 'Submitting...' : 'Submit'}
                        </button>
                        {approvalMessage && (
                            <p style={{
                                position: 'absolute',
                                color: isSent ? 'green' : 'red',
                                fontSize: '17px',
                                fontWeight: 400,
                                bottom: windowWidth < 768 ? '-10%' : '-15%'
                            }}>
                                {approvalMessage}
                            </p>
                        )}
                    </form>
                </div>
            </div>
        </div>
    )
}

export default FormSection