import React, { useState, useEffect } from 'react';
import p01 from '../../assets/GenuinFeed/p01.png';
import p02 from '../../assets/GenuinFeed/p02.png';
import pl02 from '../../assets/GenuinFeed/pl02.png';
import pl01 from '../../assets/GenuinFeed/pl01.png';
import gif1 from '../../assets/GenuinFeed/gif1.gif';
import p01_mobile from '../../assets/GenuinFeed/p01_mobile.png';
import p01_mobile_2 from '../../assets/GenuinFeed/p01_mobile_2.png';
import Modal from './Modal';

const GenuinFeed = ({ windowWidth }) => {
    const buttonStyles = {
        height: '28px',
        width: '28px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        backgroundColor: 'rgba(6, 60, 255, 0.20)',
        marginRight: '10px',
        fontSize: '17px',
        fontWeight: 500,
        lineHeight: '24px',
        flexShrink: 0
    };

    const [activeButtonIndex, setActiveButtonIndex] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleButtonHover = (index) => {
        setActiveButtonIndex(index);
    };

    // useEffect(() => {
    //     const autoHoverButtons = () => {
    //         handleButtonHover(activeButtonIndex === 0 ? 1 : 0);
    //     };
    //     const timerId = setInterval(autoHoverButtons, 5000);
    //     return () => clearInterval(timerId);
    // }, [activeButtonIndex]);


    return (
        <div>
            <div className='Genuin-container container'>
                <p className='Genuin-title'>
                Craft Your Perfect AdReels Playlist
                </p>
                <p className='Genuin-description' >
                Seamlessly integrate and showcase videos from your advertising campaigns or social feeds. Strategically position your content for optimal engagement and improved results.
                </p>
                <button type="submit" className="Ad-button" style={{ textDecoration: 'none', marginTop: 0 }} onClick={() => setIsModalOpen(true)}>
                    Learn More
                </button>
                {windowWidth > 768 && <div className='Genuin-button-container'>
                    <button
                        className={activeButtonIndex ? 'Genuin-button-secondary' : 'Genuin-button'}
                        onMouseEnter={() => handleButtonHover(0)}
                    >
                        <span style={buttonStyles}>1</span>
                        Generate with your sponsored content
                    </button>
                    <span style={{
                        // position: 'absolute',
                        textAlign: 'center',
                        fontSize: '20px',
                        fontWeight: 600,
                        lineHeight: '48px',
                        letterSpacing: '-0.8px',
                        margin: '0px 20px'
                    }}>or</span>
                    <button
                        className={activeButtonIndex ? 'Genuin-button' : 'Genuin-button-secondary'}
                        onMouseEnter={() => handleButtonHover(1)}
                    >
                        <span style={buttonStyles}>2</span>
                        Sprinkle the feed with contextually relevant UGC
                    </button>
                </div>}
                <div>
                    {windowWidth > 768 ? (
                        <>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>

                                <div className='Genuin-images-container' style={{ width: '300px', height: '400px', margin: '20px', position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <img
                                        src={pl01}
                                        style={{
                                            height: '150px',
                                            position: 'absolute',
                                            right: '-170px',
                                            opacity: activeButtonIndex === 0 ? 1 : 0,
                                            transform: activeButtonIndex === 0 ? 'translateX(0)' : 'translateX(100%)',
                                        }}
                                        alt="Image1"
                                    />
                                    <img
                                        src={pl02}
                                        style={{
                                            height: '180px',
                                            position: 'absolute',
                                            right: '-170px',
                                            opacity: activeButtonIndex === 0 ? 0 : 1,
                                            transform: activeButtonIndex === 0 ? 'translateX(100%)' : 'translateX(0)',
                                        }}
                                        alt="Image1"
                                    />
                                    <img
                                        src={windowWidth > 768 ? p01 : p01_mobile_2}
                                        style={{
                                            height: '320px',
                                            position: 'absolute',
                                            left: '-370px',
                                            // top: '20px',
                                            opacity: activeButtonIndex === 0 ? 1 : 0,
                                            transform: activeButtonIndex === 0 ? 'translateX(0)' : 'translateX(-100%)',
                                        }}
                                        alt="Image1"
                                    />
                                    <img
                                        src={p02}
                                        style={{
                                            height: '200px',
                                            position: 'absolute',
                                            left: '-280px',
                                            opacity: activeButtonIndex === 0 ? 0 : 1,
                                            transform: activeButtonIndex === 0 ? 'translateX(-100%)' : 'translateX(0)',
                                        }}
                                        alt="Image2"
                                    />
                                    {activeButtonIndex ? (
                                        <>
                                            <img
                                                src='https://media.qa.begenuin.com/cxr/adreels_landing_2.gif'
                                                style={{
                                                    height: '340px',
                                                    position: 'absolute',
                                                    right: '0'
                                                }}
                                                alt='img'
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <img
                                                src={gif1}
                                                style={{
                                                    height: '340px',
                                                    position: 'absolute',
                                                    right: '0'
                                                }}
                                                alt='img'
                                            />
                                        </>
                                    )}

                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                <div className='Genuin-button-container'>
                                    <button
                                        className='Genuin-button-secondary'
                                        onMouseEnter={() => handleButtonHover(0)}
                                    >
                                        <span style={buttonStyles}>1</span>
                                        Generate with your sponsored content
                                    </button>
                                </div>
                                <img
                                        src={p01_mobile}
                                        style={{
                                            margin: '10px',
                                            height: '142px',
                                        }}
                                        alt="Image1"
                                    />

                                <div style={{ width: '100%', height: '200px', margin: '20px', marginBottom: 0, position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <img
                                        src={p01_mobile_2}
                                        style={{
                                            height: '172px',
                                            position: 'absolute',
                                            left: '50px',
                                        }}
                                        alt="Image1"
                                    />
                                    <img
                                        src={gif1}
                                        style={{
                                            height: '160px',
                                            position: 'absolute',
                                            right: '0'
                                        }}
                                        alt='img'
                                    />
                                </div>
                                <img
                                    src={pl01}
                                    style={{
                                        height: '120px',
                                        left: 0,
                                        marginBottom: '20px'
                                    }}
                                    alt="Image1"
                                />

                                <div className='Genuin-button-container'>
                                    <button
                                        className='Genuin-button-secondary'
                                        onMouseEnter={() => handleButtonHover(1)}
                                    >
                                        <span style={buttonStyles}>2</span>
                                        Wrap your videos around contextually relevant content
                                    </button>
                                </div>

                                <div style={{ width: '100%', height: '200px', margin: '20px', marginBottom: 0, position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <img
                                        src={p02}
                                        style={{
                                            height: '124px',
                                            position: 'absolute',
                                            left: 0,
                                        }}
                                        alt="Image1"
                                    />
                                    <img
                                        src='https://media.qa.begenuin.com/cxr/adreels_landing_2.gif'
                                        style={{
                                            height: '160px',
                                            position: 'absolute',
                                            right: '0'
                                        }}
                                        alt='img'
                                    />
                                </div>
                                <img
                                    src={pl02}
                                    style={{
                                        height: '120px',
                                        left: 0,
                                        marginBottom: '20px'
                                    }}
                                    alt="Image1"
                                />
                            </div>
                        </>
                    )}
                </div>

                <Modal
                    windowWidth={windowWidth}
                    isModalOpen={isModalOpen}
                    closeModal={() => setIsModalOpen(false)}
                />

            </div>
        </div>
    );
};

export default GenuinFeed;
