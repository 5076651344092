import React, { useState, useRef, useEffect } from 'react';
import mobilelogo from '../../assets/AdTags/mobilelogo.svg'
import tabletlogo from '../../assets/AdTags/tabletlogo.svg';
import desktoplogo from '../../assets/AdTags/desktoplogo.svg';
import mobile from '../../assets/AdTags/mobile.svg';
import tablet from '../../assets/AdTags/tablet.svg';
import desktop from '../../assets/AdTags/desktop.svg';
import long_image_ipad_1 from '../../assets/AdTags/long_image_ipad_1.png';
import long_image_desktop_1 from '../../assets/AdTags/long_image_desktop_1.png';
import long_image_mobile_1 from '../../assets/AdTags/long_image_mobile_1.png';
import long_image_ipad_2 from '../../assets/AdTags/long_image_ipad_2.png';
import long_image_desktop_2 from '../../assets/AdTags/long_image_desktop_2.png';
import long_image_mobile_2 from '../../assets/AdTags/long_image_mobile_2.png';
import background_stripes from '../../assets/AdTags/background_stripes.svg';
import bg_stripes_mobile from '../../assets/AdTags/bg_stripes_mobile.svg';
import Modal from './Modal';

const MobileSection = ({ windowWidth, width, height }) => {
    const iframeRef = useRef(null);


    useEffect(() => {
        const iframeStyles = {
            '300x600': { top: '87px', left: '-48px', scale: windowWidth > 768 ? `0.5` : '0.35' },
            '240x400': { top: '68px', left: '-54px', scale: windowWidth > 768 ? `0.46` : '0.35' },
            '120x240': { top: '110px', left: '-22px', scale: windowWidth > 768 ? `0.46` : '0.35' },
            '250x250': { top: '110px', left: '-24px', scale: windowWidth > 768 ? `0.46` : '0.35' },
            '300x250': { top: '219px', left: '-48px', scale: windowWidth > 768 ? `0.56` : '0.35' },
            '336x280': { top: '219px', left: '-48px', scale: windowWidth > 768 ? `0.56` : '0.35' },
            '720x300': { top: '133px', left: '-38px', scale: windowWidth > 768 ? `0.63` : '0.2' },
            '160x600': { top: '-78px', left: '396px', scale: windowWidth > 768 ? `0.5` : '0.2' },
            '120x600': { top: '-78px', left: '416px', scale: windowWidth > 768 ? `0.5` : '0.2' }
        };

        const mobileiframeStyles = {
            '300x600': { top: '32px', left: '-48px', scale: '0.43' },
            '240x400': { top: '20px', left: '-70px', scale: '0.35' },
            '120x240': { top: '68px', left: '-28px', scale: '0.35' },
            '250x250': { top: '68px', left: '-23px', scale: '0.35' },
            '300x250': { top: '170px', left: '-48px', scale: '0.5' },
            '336x280': { top: '68px', left: '-68px', scale: '0.35' },
            '720x300': { top: 'unset', left: 'unset' },
            '160x600': { top: 'unset', left: 'unset' },
            '120x600': { top: 'unset', left: 'unset' }
        };

        const imageSizeKey = `${width}x${height}`;
        const iframeStyle = windowWidth < 768 ? mobileiframeStyles[imageSizeKey] : iframeStyles[imageSizeKey];

        if (iframeStyle) {
            const iframeElement = iframeRef.current;
            if (iframeElement) {
                iframeElement.style.top = iframeStyle.top;
                iframeElement.style.left = iframeStyle.left;
                iframeElement.style.scale = iframeStyle.scale
            }
        }
    }, [width, height]);


    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: 'white',
            height: '425px',
            width: '204px',
            position: 'absolute',
            borderRadius: '0px 0px 28px 28px',
            overflow: 'auto',
            bottom: '80px'
        }}>
            <style>
                {`
        div::-webkit-scrollbar {
        width: 0px;
        }
        div::-webkit-scrollbar-thumb {
        background: transparent;
        }
    `}
            </style>
            <img
                src={`${width}x${height}` === '300x600' ? long_image_mobile_1 : long_image_mobile_2}
                alt="Your Image"
                className="genuin-mockup-img"
                style={{
                    maxWidth: '100%',
                    height: windowWidth > 768 ? '700px' : '600px'
                }}
            />
            <iframe
                ref={iframeRef}
                src={`/iframe.html?width=${width}&height=${height}`}
                title="Embedded Website"
                frameBorder="0"
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    width: `${width}px`,
                    height: `${height}px`,
                    zIndex: 5
                }}
            />
        </div>
    );
}


const TabletSection = ({ windowWidth, width, height }) => {
    const iframeRef = useRef(null);


    useEffect(() => {
        const iframeStyles = {
            '300x600': { top: '-85px', left: '296px', scale: windowWidth > 768 ? `0.4` : '0.24' },
            '240x400': { top: '136px', left: '360px', scale: windowWidth > 768 ? `0.35` : '0.24' },
            '120x240': { top: '194px', left: '400px', scale: windowWidth > 768 ? `0.35` : '0.24' },
            '250x250': { top: '264px', left: '406px', scale: windowWidth > 768 ? `0.35` : '0.24' },
            '300x250': { top: '21px', left: '297px', scale: windowWidth > 768 ? `0.4` : '0.24' },
            '336x280': { top: '246px', left: '360px', scale: windowWidth > 768 ? `0.35` : '0.24' },
            '720x300': { top: '133px', left: '-38px', scale: windowWidth > 768 ? `0.63` : '0.2' },
            '160x600': { top: '-78px', left: '396px', scale: windowWidth > 768 ? `0.5` : '0.2' },
            '120x600': { top: '-78px', left: '416px', scale: windowWidth > 768 ? `0.5` : '0.2' }
        };

        const mobileiframeStyles = {
            '300x600': { top: '-166px', left: '109px', scale: '0.24' },
            '240x400': { top: '-30px', left: '121px', scale: '0.18' },
            '120x240': { top: '36px', left: '170px', scale: '0.18' },
            '250x250': { top: '32px', left: '116px', scale: '0.18' },
            '300x250': { top: '-34px', left: '108px', scale: '0.25' },
            '336x280': { top: '22px', left: '82px', scale: '0.18' },
            '720x300': { top: 'unset', left: 'unset' },
            '160x600': { top: 'unset', left: 'unset' },
            '120x600': { top: 'unset', left: 'unset' }
        };

        const imageSizeKey = `${width}x${height}`;
        const iframeStyle = windowWidth < 768 ? mobileiframeStyles[imageSizeKey] : iframeStyles[imageSizeKey];

        if (iframeStyle) {
            const iframeElement = iframeRef.current;
            if (iframeElement) {
                iframeElement.style.top = iframeStyle.top;
                iframeElement.style.left = iframeStyle.left;
                iframeElement.style.scale = iframeStyle.scale
            }
        }
    }, [width, height]);

    return (
        <div style={{
            display: 'flex',
            backgroundColor: 'white',
            height: windowWidth > 768 ? '406px' : '226px',
            width: windowWidth > 768 ? '576px' : '326px',
            position: 'absolute',
            borderRadius: '10px',
            overflow: 'auto',
        }}>
            <style>
                {`
            div::-webkit-scrollbar {
            width: 0px;
            }
            div::-webkit-scrollbar-thumb {
            background: transparent;
            }
        `}
            </style>
            <img
                src={`${width}x${height}` === '300x600' ? long_image_ipad_1 : long_image_ipad_2}
                alt="Your Image"
                className="genuin-mockup-img"
                style={{
                    maxWidth: '100%',
                    height: windowWidth > 768 ? '800px' : '500px'
                    // width: '100%'
                }}
            />
            <iframe
                ref={iframeRef}
                src={`/iframe.html?width=${width}&height=${height}`}
                title="Embedded Website"
                frameBorder="0"
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    width: `${width}px`,
                    height: `${height}px`,
                    zIndex: 1
                }}
            />
        </div>
    );
}


const DesktopSection = ({ windowWidth, width, height }) => {
    const iframeRef = useRef(null);


    useEffect(() => {
        const iframeStyles = {
            '300x600': { top: '-68px', left: '328px', scale: windowWidth > 768 ? `0.47` : '0.2' },
            '240x400': { top: 'unset', left: 'unset' },
            '120x240': { top: 'unset', left: 'unset' },
            '250x250': { top: 'unset', left: 'unset' },
            '300x250': { top: '41px', left: '329px', scale: windowWidth > 768 ? `0.47` : '0.2' },
            '336x280': { top: 'unset', left: 'unset' },
            '720x300': { top: '133px', left: '-38px', scale: windowWidth > 768 ? `0.63` : '0.2' },
            '160x600': { top: '-78px', left: '396px', scale: windowWidth > 768 ? `0.5` : '0.2' },
            '120x600': { top: '-78px', left: '416px', scale: windowWidth > 768 ? `0.5` : '0.2' }
        };

        const mobileiframeStyles = {
            '300x600': { top: '-184px', left: '75px', scale: '0.23' },
            '240x400': { top: 'unset', left: 'unset' },
            '120x240': { top: 'unset', left: 'unset' },
            '250x250': { top: 'unset', left: 'unset' },
            '300x250': { top: '-42px', left: '74px' },
            '336x280': { top: 'unset', left: 'unset' },
            '720x300': { top: '-10px', left: '-208px', scale: '0.3' },
            '160x600': { top: '-188px', left: '144px', scale: '0.25' },
            '120x600': { top: '-188px', left: '164px', scale: '0.25' }
        };

        const imageSizeKey = `${width}x${height}`;
        const iframeStyle = windowWidth < 768 ? mobileiframeStyles[imageSizeKey] : iframeStyles[imageSizeKey];

        if (iframeStyle) {
            const iframeElement = iframeRef.current;
            if (iframeElement) {
                iframeElement.style.top = iframeStyle.top;
                iframeElement.style.left = iframeStyle.left;
                iframeElement.style.scale = iframeStyle.scale
            }
        }
    }, [width, height]);

    return (
        <div style={{
            display: 'flex',
            backgroundColor: 'white',
            height: windowWidth > 768 ? '412px' : '190px',
            width: windowWidth > 768 ? '658px' : '303px',
            position: 'absolute',
            // borderRadius: '10px',
            overflow: 'auto',
            top: windowWidth > 768 ? '77px' : '197px'
        }}>
            <style>
                {`
            div::-webkit-scrollbar {s
            width: 0px;
            }
            div::-webkit-scrollbar-thumb {
            background: transparent;
            }
        `}
            </style>
            <img
                src={`${width}x${height}` === '300x600' ? long_image_desktop_1 : long_image_desktop_2}
                alt="Your Image"
                className="genuin-mockup-img"
                style={{
                    maxWidth: '100%',
                    height: windowWidth > 768 ? '1000px' : '500px'
                }}
            />
            <iframe
                ref={iframeRef}
                src={`/iframe.html?width=${width}&height=${height}`}
                title="Embedded Website"
                frameBorder="0"
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    backgroundColor: 'white',
                    width: `${width}px`,
                    height: `${height}px`,
                    zIndex: 1,
                }}
            />
        </div>
    );
}

const AdTags = ({ windowWidth }) => {
    const iframeRef = useRef(null);
    const [height, setHeight] = useState(600);
    const [width, setWidth] = useState(300);
    const [activeButton, setActiveButton] = useState('300x600'); // Initialize with "300x600" as active button



    useEffect(() => {
        const iframeStyles = {
            '300x600': { top: '-88px', left: '324px' },
            '240x400': { top: 'unset', left: 'unset' },
            '120x240': { top: 'unset', left: 'unset' },
            '250x250': { top: 'unset', left: 'unset' },
            '300x250': { top: 'unset', left: 'unset' },
            '336x280': { top: 'unset', left: 'unset' },
            '720x300': { top: 'unset', left: 'unset' },
            '160x600': { top: 'unset', left: 'unset' },
            '120x600': { top: 'unset', left: 'unset' }
        };

        const mobileiframeStyles = {
            '300x600': { top: 'unset', left: 'unset' },
            '240x400': { top: 'unset', left: 'unset' },
            '120x240': { top: 'unset', left: 'unset' },
            '250x250': { top: 'unset', left: 'unset' },
            '300x250': { top: 'unset', left: 'unset' },
            '336x280': { top: 'unset', left: 'unset' },
            '720x300': { top: 'unset', left: 'unset' },
            '160x600': { top: 'unset', left: 'unset' },
            '120x600': { top: 'unset', left: 'unset' }
        };

        const imageSizeKey = `${width}x${height}`;
        const iframeStyle = windowWidth < 768 ? mobileiframeStyles[imageSizeKey] : iframeStyles[imageSizeKey];

        if (iframeStyle) {
            const iframeElement = iframeRef.current;
            if (iframeElement) {
                iframeElement.style.top = iframeStyle.top;
                iframeElement.style.left = iframeStyle.left;
                iframeElement.style.bottom = iframeStyle.bottom;
            }
        }
    }, [width, height]);

    const sizeToImageMap = {
        '300x600': mobile,
        '240x400': mobile,
        '120x240': mobile,
        '250x250': mobile,
        '300x250': mobile,
        '336x280': mobile,
        '720x300': desktop,
        '160x600': desktop,
        '120x600': desktop
    };

    const handleButtonClick = (newWidth, newHeight, buttonName) => {
        setHeight(newHeight);
        setWidth(newWidth);
        setActiveButton(buttonName); // Set the active button when clicked

        const imageSizeKey = `${newWidth}x${newHeight}`;
        const newImageSource = sizeToImageMap[imageSizeKey];

    };



    // ------------------------------------------------------------------------------------------------------------------------   const iframeRef = useRef(null);
    const [selectedButton, setSelectedButton] = useState('Desktop');
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleScreenButtonClick = (buttonName) => {
        setSelectedButton(buttonName);
        setHeight(600);
        setWidth(300);
        setActiveButton('300x600');
    };

    const adItemStyle = {
        margin: windowWidth > 768 ? '0px' : '10px',
        padding: windowWidth > 768 ? '8px 16px' : '4px 12px',
        height: '32px',
        width: '100%',
        borderRadius: '10px',
        backgroundColor: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: 0,
        cursor: 'pointer',
    };

    const AdButton = ({ label, selectedButton, onClick, imgSrc }) => (
        <div
            style={{
                ...adItemStyle,
                backgroundColor: selectedButton === label ? 'rgba(179, 221, 226, 0.3)' : '#F3F3F3',
                border: selectedButton === label ? '1px solid #B3DDE2' : 0
            }}
            onClick={() => onClick(label)}
        >
            {windowWidth > 768 && <img src={imgSrc} style={{ height: '32px', marginRight: '10px' }} alt="Your Image" className="genuin-mockup-img" />}
            {label}
        </div>
    );


    let selectedImageSrc = '';
    let selectedImageHeight = '500px';

    switch (selectedButton) {
        case 'Mobile':
            selectedImageSrc = mobile;
            selectedImageHeight = '500px';
            break;
        case 'Tablet':
            selectedImageSrc = tablet;
            selectedImageHeight = windowWidth > 768 ? '450px' : '250px';
            break;
        case 'Desktop':
            selectedImageSrc = desktop;
            selectedImageHeight = windowWidth > 768 ? '500px' : '230px';
            break;
        default:
            selectedImageSrc = mobile;
    }

    const divStyle = {
        backgroundImage: windowWidth > 768 ? `url(${background_stripes})` : `url(${bg_stripes_mobile})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        zIndex: 1
    };


    return (
        <div style={divStyle}>
            <div className="AdTag-container container">
                <div className="AdTag-text-box">
                    <div>
                        <span className="AdTag-text-1">Seamless Multi-Screen Video Experience</span><br />
                        <br />
                        <span className="AdTag-text-2">Engage your audience effortlessly with swipeable video content, optimized for every screen. Experience the advantage of strategically placed short videos that resonate across the digital landscape.
                        </span>
                    </div>
                    {windowWidth < 768 && <button type="submit" className="Ad-button" style={{ textDecoration: 'none' }} onClick={() => setIsModalOpen(true)}>
                        Know More
                    </button>}
                </div>


                <div className='AdTag-ad-box'>
                    <div className="ad-container">
                        <AdButton label="Desktop" selectedButton={selectedButton} onClick={handleScreenButtonClick} imgSrc={desktoplogo} />
                        <AdButton label="Tablet" selectedButton={selectedButton} onClick={handleScreenButtonClick} imgSrc={tabletlogo} />
                        <AdButton label="Mobile" selectedButton={selectedButton} onClick={handleScreenButtonClick} imgSrc={mobilelogo} />
                    </div>
                    <div className='AdTags-button-container'>

                        <button className='AdTags-buttom' style={{ backgroundColor: activeButton === '300x600' ? 'rgba(254, 198, 46, 1)' : 'rgba(254, 198, 46, 0.20)', border: '1px solid #FEC62E' }} onClick={() => handleButtonClick(300, 600, '300x600')}>300x600</button>
                        <button className='AdTags-buttom' style={{ backgroundColor: activeButton === '300x250' ? 'rgba(213, 255, 122, 1)' : 'rgba(213, 255, 122, 0.20)', border: '1px solid #D5FF7A' }} onClick={() => handleButtonClick(300, 250, '300x250')}>300x250</button>
                        {/* {selectedButton === 'Desktop' ? (
                            <>
                                <button className='AdTags-buttom' style={{ backgroundColor: activeButton === '720x300' ? 'rgba(254, 198, 46, 1)' : 'rgba(254, 198, 46, 0.20)', border: activeButton === '720x300' ? '4px solid #B3DDE2' : '1px solid #FEC62E' }} onClick={() => handleButtonClick(720, 300, '720x300')}>720x300</button>
                                <button className='AdTags-buttom' style={{ backgroundColor: activeButton === '160x600' ? 'rgba(235, 109, 74, 1)' : 'rgba(235, 109, 74, 0.20)', border: activeButton === '160x600' ? '4px solid #B3DDE2' : '1px solid #EB6D4A' }} onClick={() => handleButtonClick(160, 600, '160x600')}>160x600</button>
                                <button className='AdTags-buttom' style={{ backgroundColor: activeButton === '120x600' ? 'rgba(164, 130, 198, 1)' : 'rgba(164, 130, 198, 0.20)', border: activeButton === '120x600' ? '4px solid #B3DDE2' : '1px solid #A482C6' }} onClick={() => handleButtonClick(120, 600, '120x600')}>120x600</button>
                            </>
                        ) : (
                            <>
                                <button className='AdTags-buttom' style={{ backgroundColor: activeButton === '240x400' ? 'rgba(164, 230, 218, 1)' : 'rgba(164, 230, 218, 0.20)', border: activeButton === '240x400' ? '4px solid #B3DDE2' : '1px solid #A4E6DA', }} onClick={() => handleButtonClick(240, 400, '240x400')}>240x400</button>
                                <button className='AdTags-buttom' style={{ backgroundColor: activeButton === '120x240' ? 'rgba(164, 130, 198, 1)' : 'rgba(164, 130, 198, 0.20)', border: activeButton === '120x240' ? '4px solid #B3DDE2' : '1px solid #A482C6' }} onClick={() => handleButtonClick(120, 240, '120x240')}>120x240</button>
                                <button className='AdTags-buttom' style={{ backgroundColor: activeButton === '250x250' ? 'rgba(84, 200, 232, 1)' : 'rgba(84, 200, 232, 0.20)', border: activeButton === '250x250' ? '4px solid #B3DDE2' : '1px solid #54C8E8' }} onClick={() => handleButtonClick(250, 250, '250x250')}>250x250</button>
                                <button className='AdTags-buttom' style={{ backgroundColor: activeButton === '336x280' ? 'rgba(84, 200, 232, 1)' : 'rgba(84, 200, 232, 0.20)', border: activeButton === '336x280' ? '4px solid #B3DDE2' : '1px solid #54C8E8' }} onClick={() => handleButtonClick(336, 280, '336x280')}>336x280</button>
                            </>
                        )} */}



                    </div>

                    <div className='AdTag-device-box' >

                        {selectedButton === 'Mobile' && (
                            <MobileSection windowWidth={windowWidth} width={width} height={height} />
                        )}

                        {selectedButton === 'Tablet' && (
                            <TabletSection windowWidth={windowWidth} width={width} height={height} />
                        )}

                        {selectedButton === 'Desktop' && (
                            <DesktopSection windowWidth={windowWidth} width={width} height={height} />
                        )}

                        <img
                            src={selectedImageSrc}
                            style={{ height: selectedImageHeight }}
                            alt="Your Image"
                            className="genuin-mockup-img"
                        />
                        {windowWidth > 768 && <button type="submit" className="Ad-button" style={{ textDecoration: 'none', position: 'absolute', right: 0 }} onClick={() => setIsModalOpen(true)}>
                            Learn More
                        </button>}
                    </div>

                    <Modal
                        windowWidth={windowWidth}
                        isModalOpen={isModalOpen}
                        closeModal={() => setIsModalOpen(false)}
                    />

                </div>
            </div>
        </div>
    )
}

export default AdTags
