import React from 'react';
import close from '../../assets/Form/close.svg';
import Form from './Form';

function Modal({ windowWidth, isModalOpen, closeModal, email }) {
    return (
        isModalOpen && (
            <div
                className="modal-overlay"
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 100,
                }}
                onClick={closeModal}
            >
                <div
                    className="modal"
                    style={{
                        width: windowWidth < 768 ? '90%' : '500px',
                        borderRadius: '5px',
                        display: 'flex',
                        backgroundColor: 'white',
                        flexDirection: 'column',
                    }}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <div
                        style={{
                            position: 'relative',
                            padding: '30px',
                            borderRadius: '5px',
                            overflow: 'hidden',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <img
                            src={close}
                            alt="close"
                            style={{
                                position: 'absolute',
                                top: '12px',
                                right: '12px',
                                opacity: '0.5',
                                cursor: 'pointer',
                            }}
                            onClick={closeModal}
                        />
                        <div className="Modal-text">
                            <p>
                                <span className="Modal-title">See It In Action</span>
                                <br />
                                <span className="Modal-description">Connect with us for a demo - complete the form below, and our experts will reach out to get you started.</span>
                            </p>
                        </div>
                        <Form email={email} />
                    </div>
                </div>
            </div>
        )
    );
}

export default Modal;
