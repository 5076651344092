import React, { useState } from 'react';
import dashboard from '../../assets/DashBoard/dashboard.svg';
import up from '../../assets/DashBoard/up.svg';
import down from '../../assets/DashBoard/down.svg';
import Modal from './Modal';

const Dashboard = ({ windowWidth }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const marqueeLines = [
        [
            { text: 'Swipe to Engagement' },
            { text: 'Engagement Rate (%)' },
            { text: 'Video Feed Combination Analysis' },
            { text: 'Recommendation and Optimization Engine' },
            { text: 'Click Metrics' }
        ],
        [
            { text: 'Recommendation and Optimization Engine' },
            { text: 'Click Metrics' },
            { text: 'VCR Metrics' },
            { text: 'Attention Stats' },
            { text: 'Individual Video Engagement Analysis' }
        ],
        [
            { text: 'VCR Metrics' },
            { text: 'Attention Stats' },
            { text: 'Individual Video Engagement Analysis' },
            { text: 'Swipe to Engagement' },
            { text: 'Engagement Rate (%)' },
            { text: 'Video Feed Combination Analysis' }
        ]
    ];

    const MarqueeItem = ({ text }) => (
        <div className='Dashboard-info-box'>
            {text}
            <img style={{ height: '20px', marginLeft: '4px' }} src={text === 'Swipe to Engagement' || text === 'Attention Stats' ? down : up} alt="up" />
        </div>
    );

    return (
        <div>
            <div className='Dashboard-container container'>
                <p className='Dashboard-title'>
                    Industry-Leading Stream Level Analytics and Reporting
                </p>
                <p className='Dashboard-description'>
                Genuin AdReels offers detailed, transparent reporting across the feed. Discover which videos and their sequences capture the most attention and uncover the reasons. Integrate effortlessly with third-party analytics and attribution platforms to measure the impact of Genuin AdReels.
                </p>
                {windowWidth < 768 && <button type="submit" className="Ad-button" style={{ textDecoration: 'none', marginTop: 0 }} onClick={() => setIsModalOpen(true)}>
                            Learn More
                        </button>}

                <div>
                    {marqueeLines.map((line, lineIndex) => (
                        <div>
                            <div key={lineIndex} className="marquee">
                                <div className={lineIndex % 2 === 0 ? "marquee__group_ltr" : "marquee__group_rtl"}>
                                    {line.map((item, wordIndex) => (
                                        <MarqueeItem
                                            key={wordIndex}
                                            text={item.text}
                                            backgroundColor={item.backgroundColor}
                                            borderColor={item.borderColor}
                                        />
                                    ))}
                                </div>
                                <div aria-hidden="true" className={lineIndex % 2 === 0 ? "marquee__group_ltr" : "marquee__group_rtl"}>
                                    {line.map((item, wordIndex) => (
                                        <MarqueeItem
                                            key={wordIndex}
                                            text={item.text}
                                            backgroundColor={item.backgroundColor}
                                            borderColor={item.borderColor}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}

                    <div className='Dashboard-image-war'>
                        <img className='Dashboard-image' src={dashboard} alt="dashboard" />
                        {windowWidth > 768 && <button type="submit" className="Ad-button" style={{ textDecoration: 'none', position: 'absolute', right: '150px' }} onClick={() => setIsModalOpen(true)}>
                            Know More
                        </button>}
                    </div>

                    <Modal
                        windowWidth={windowWidth}
                        isModalOpen={isModalOpen}
                        closeModal={() => setIsModalOpen(false)}
                    />
                </div>
            </div>
        </div>
    );
}

export default Dashboard;