import React, { useState } from "react";
import vector1 from "../../assets/AdReel/vector1.png";
import vector2 from "../../assets/AdReel/vector2.png";
import Modal from "./Modal";

const AdReel_01 = ({ windowWidth }) => {
  const [email, setEmail] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const spanStyle = {
    "--color": "#B3DDE2",
    "--position": "center bottom",
    "--width": "100%",
    "--height": "15px",
    background: `linear-gradient(var(--color), var(--color)) var(--position) / var(--width) var(--height) no-repeat`
  };

  return (
    <>
      <div className="AdReel-container container">
        <div className="AdReel-mockup-box">
          <div className="AdReel-video-container">
            <video
              className="AdReel-mockup-video"
              controls={false}
              loop={true}
              autoPlay
              muted
              playsInline
            >
              <source
                src="https://creatives-genuin-qa.s3.amazonaws.com/asset-creative/herogifupdated.mp4"
                type="video/mp4"
              />
            </video>

            <img
              src={vector1}
              alt="Your Image"
              style={{
                position: "absolute",
                height: windowWidth > 768 ? "400px" : "320px",
                bottom: 0,
                filter: 'blur(20px)'
              }}
            />
            <img
              src={vector2}
              alt="Your Image"
              style={{
                position: "absolute",
                height: windowWidth > 768 ? "500px" : "420px",
                top: 0,
              }}
            />
          </div>
        </div>

        <div className="AdReel-text-box">
          <div className="AdReel-text-comp">
            <span className="AdReel-title">
              Elevate <span style={spanStyle}>Engagement</span> and{" "}
              <span style={spanStyle}>Attention</span> with{" "}
              <span style={{ color: "#0645FF" }}>Genuin AdReels</span>
            </span>
            <br />
            <br />
            <span className="AdReel-description">
              Genuin AdReels is a multi-video playlist that drives unprecedented
              user engagement and provides granular stream-level insights
            </span>
          </div>

          <div>
            <form
              onSubmit={(e) => e.preventDefault()}
              style={{
                display: "flex",
                // alignItems: "center",
              }}
            >
              <input
                id="email"
                type="email"
                placeholder="Enter your email"
                fullWidth
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{
                  backgroundColor: '#FAFAFA',
                  borderRadius: "5px",
                  width: "50%",
                  padding: "16px",
                  border: "1px solid #ccc",
                }}
              />
              <button
                type="submit"
                className="AdReel-button"
                style={{ textDecoration: "none" }}
                onClick={() => setIsModalOpen(true)}
              >
                Get started
              </button>
            </form>
          </div>
        </div>

        <Modal
          windowWidth={windowWidth}
          isModalOpen={isModalOpen}
          closeModal={() => setIsModalOpen(false)}
          email={email}
        />
      </div>
    </>
  );
};

export default AdReel_01;
