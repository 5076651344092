import React, { useEffect, useState } from 'react';
import NavigationBar from './home/NavigationBar'
import AdReel_01 from './home/AdReel_01';
import Preview from './home/Preview';
import AdTags from './home/AdTags';
import Features from './home/Features';
import GenuinFeed from './home/GenuinFeed';
import Dashboard from './home/Dashboard';
import Results from './home/Results';
import Brands from './home/Brands';
import FormSection from './home/FormSection';
import SocialApps from './home/SocialApps';

const Home = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      <div className="sticky-nav">
        <NavigationBar windowWidth={windowWidth} />
      </div>
      <div className="scrollable-content" style={{ backgroundColor: '#FAFAFA'}}>
        <AdReel_01 windowWidth={windowWidth}/>
        <Preview windowWidth={windowWidth}/>
        <AdTags windowWidth={windowWidth}/>
        <GenuinFeed windowWidth={windowWidth}/>
        <SocialApps windowWidth={windowWidth}/>
        <Dashboard windowWidth={windowWidth}/>
        <Results windowWidth={windowWidth}/>
        {/* <Brands windowWidth={windowWidth} /> */}
        <Features windowWidth={windowWidth}/>
        <FormSection windowWidth={windowWidth}/>
      </div>
    </div>
  )
}

export default Home