import React, { useState } from 'react'
import { apiurl } from '../../config';
import TextField from '@mui/material/TextField';
import insta from '../../assets/Form/instagram.svg';
import tiktok from '../../assets/Form/tiktok.svg';

const Form = ({ email }) => {
  const initialFormData = {
    firstName: '',
    lastName: '',
    email: email ? `${email}` : '',
    mobile: '',
    igusername: '',
    tiktokusername: '',
  };

  const [formData, setFormData] = useState(initialFormData);
  const [approvalMessage, setApprovalMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setApprovalMessage('');

    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!emailPattern.test(formData.email)) {
      setApprovalMessage('Enter a valid email address');
      return;
    }

    // if (!formData.igusername && !formData.tiktokusername) {
    //   setApprovalMessage('Please enter at least one username');
    //   return;
    // }

    let socialMediaData = {};

    if (formData.igusername) {
      socialMediaData.instagram = formData.igusername;
    }
    if (formData.tiktokusername) {
      socialMediaData.tiktok = formData.tiktokusername;
    }

    const formattedMobile = '+1' + formData.mobile;

    const formmetedData = {
      email: formData.email,
      name: `${formData.firstName} ${formData.lastName}`, // Merge first name and last name
      mobile: formattedMobile,
      ...socialMediaData,
    };

    const apiUrl = `${apiurl}/api/v3/public/brand_integration`;

    setIsLoading(true);
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formmetedData),
      });

      if (response.status === 200) {
        setIsSent(true);
        setApprovalMessage('Thank You For Your Response!');
        setIsLoading(false);
        setFormData(initialFormData);

        window.open(
          `https://meetings-eu1.hubspot.com/joshua-winograd?embed=true&email=${formData.email}&firstName=${formData.firstName}&lastName=${formData.lastName}`,
          '_blank'
        );
      } else {
        setApprovalMessage('Something went wrong; please try again later.');
        setIsLoading(false);
      }

      setTimeout(() => {
        setApprovalMessage('');
      }, 5000);
    } catch (error) {
      console.error('Error while sending data:', error);
      setIsLoading(false);
    }
  };

  return (
    <div className='Modal-container'>
      <div style={{
        display: 'flex',
        justifyContent: 'center'
      }}>
        <form onSubmit={handleSubmit}>
          <div style={{
            display: 'flex'
          }}>
            <TextField
              id="outlined-basic"
              label="First Name"
              variant="outlined"
              fullWidth
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              margin="normal"
              className="Modal-text-field"
              inputProps={{ style: { fontSize: 10 } }}
              InputLabelProps={{ style: { fontSize: 12 } }}
              required
              style={{
                marginRight: '10px'
              }}
            />
            <TextField
              id="outlined-basic"
              label="Last Name"
              variant="outlined"
              fullWidth
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              margin="normal"
              className="Modal-text-field"
              inputProps={{ style: { fontSize: 10 } }}
              InputLabelProps={{ style: { fontSize: 12 } }}
              required
            />
          </div>
          <TextField
            id="outlined-basic"
            label="Company Email"
            variant="outlined"
            fullWidth
            name="email"
            value={formData.email}
            onChange={handleChange}
            margin="normal"
            className='Modal-text-field'
            inputProps={{ style: { fontSize: 10 } }}
            InputLabelProps={{ style: { fontSize: 12 } }}
            required
          />
          <TextField
            id="outlined-basic"
            label="Phone Number"
            variant="outlined"
            fullWidth
            name="mobile"
            value={formData.mobile}
            onChange={handleChange}
            margin="normal"
            className='Modal-text-field'
            inputProps={{ style: { fontSize: 10 } }}
            InputLabelProps={{ style: { fontSize: 12 } }}
            required
          />
          {/* <div className='Modal-select-container'>
            <div className='Modal-select-label'>
              <img src={insta} style={{ height: '30px' }} />
            </div>
            <div className='Modal-username-field'>
              <TextField
                id="outlined-basic"
                label="Enter Username"
                variant="outlined"
                fullWidth
                name="igusername"
                value={formData.igusername}
                onChange={handleChange}
                margin="normal"
                inputProps={{ style: { fontSize: 10 } }}
                InputLabelProps={{ style: { fontSize: 12 } }}
              />
            </div>
          </div> */}
          {/* <div className='Modal-select-container'>
          <div className='Modal-select-label'>
            <img src={tiktok} style={{ height: '30px', opacity: '30%' }} />
          </div>
          <div className='Modal-username-field'>
            <TextField
              id="outlined-basic"
              label="Enter Username"
              variant="outlined"
              fullWidth
              name="tiktokusername"
              value={formData.tiktokusername}
              onChange={handleChange}
              margin="normal"
              disabled={true}
              inputProps={{ style: { fontSize: 10 } }}
              InputLabelProps={{ style: { fontSize: 12 } }}
            />
          </div>
        </div> */}
          <button className='Modal-Form-submit-button' type="submit" disabled={isLoading}>
            {isLoading ? 'Submitting...' : 'Submit'}
          </button>
        </form>
        {approvalMessage && (
          <p style={{
            position: 'absolute',
            color: isSent ? 'green' : 'red',
            fontSize: '14px',
            fontWeight: 400,
            bottom: '0'
          }}>
            {approvalMessage}
          </p>
        )}
      </div>
    </div>
  )
}

export default Form