import React, { useState } from 'react'
import Modal from './Modal';

const Results = ({ windowWidth }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);


    return (
        <div >
            <div className='Result-container container'>
                <div className='Result-text-comp'>
                    <p className='Result-title'>
                    Beat Banner Blindness and Ad Fatigue
                    </p>
                    <p className='Result-description'>
                    AdReels provides attention, engagement, retention and action. Our latest pilot is achieving remarkable outcomes, establishing new industry-level benchmarks for engagement rates compared to traditional ad formats.
                    </p>
                    <button type="submit" className="Ad-button" style={{ textDecoration: 'none', width: '160px' }} onClick={() => setIsModalOpen(true)}>
                        Learn More
                    </button>
                </div>
                <div className='Result-image-comp'>
                    <p className='Result-card-text'>
                        <span className='Result-card-styled-text-1'>20~30%&nbsp;</span><br /> User engagement (with auto-play)<br /> <span className='Result-card-styled-text-1' style={{ fontSize: '24px' }}>&lt;0.5%&nbsp;</span> with traditional medium
                    </p>
                    <p className='Result-card-text'>
                        <span className='Result-card-styled-text-2'>&gt;10%&nbsp;</span> <br />Users see 3+ videos in a feed
                    </p>
                    <p className='Result-card-text'>
                        <span className='Result-card-styled-text-3'>40x&nbsp;</span> <br /> User engagement (with click-to-play)<br /> <span className='Result-card-styled-text-3' style={{ fontSize: '24px' }}>0.05%&nbsp;</span> with traditional medium
                    </p>
                </div>
                <Modal
                    windowWidth={windowWidth}
                    isModalOpen={isModalOpen}
                    closeModal={() => setIsModalOpen(false)}
                />
            </div>
        </div>
    )
}

export default Results