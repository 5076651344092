import React, { useState } from 'react';
import logo from '../../assets/NavBar/logo.svg';
import Modal from './Modal';

function NavigationBar({ windowWidth }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div style={{ backgroundColor: '#FAFAFA'}}>
      <nav className="navbar container">
        <div className="navbar-logo">
          <img src={logo} alt="Company Logo" />
        </div>
        <div className="navbar-buttons">
          {/* <a onClick={() => setIsModalOpen(true)} className="navbar-button" style={{ textDecoration: 'none', marginRight: '20px' }}>
            Join Our Team
          </a> */}
          <a onClick={() => setIsModalOpen(true)} className="navbar-button" style={{ textDecoration: 'none' }}>
            Get started
          </a>
        </div>
      </nav>
      <hr style={{ padding: 0, margin: 0 }} />

      <Modal
        windowWidth={windowWidth}
        isModalOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
      />
    </div>
  );
}

export default NavigationBar;
