import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import adreel1 from '../../assets/Features/adreel1.svg';
import adreel2 from '../../assets/Features/adreel2.svg';
import adreel3 from '../../assets/Features/adreel3.svg';
import adreel4 from '../../assets/Features/adreel4.svg';

const Features = ({ windowWidth }) => {
    const features = [
        {
            id: 1,
            image: adreel1,
            heading: 'Immersive social experience',
            description: 'Our video feed enables advertisers to run multiple short-form video content as an ad, capturing higher user attention, engagement, and interaction, ultimately leading to more effective campaigns.'
        },
        {
            id: 2,
            image: adreel2,
            heading: 'Get more bang for your buck',
            description: 'Our feed allows multiple videos within one placement, enhancing the value of the real estate.'
        },
        {
            id: 3,
            image: adreel3,
            heading: 'Enhanced efficiency and control',
            description: 'Access comprehensive engagement statistics for the entire feed and its content, empowering smarter decisions and boosting campaign performance, optimizing audience engagement.'
        },
        {
            id: 4,
            image: adreel4,
            heading: 'Unlock massive scale and reach',
            description: 'Plug our endpoint into any DSP or publisher partner, allowing you to reach your audience across the open web.'
        },
    ];

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        arrows: false,
    };

    return (
        <div>
            <div className='container feature-container'>
                <p className='feature-title'>
                    Why Genuin AdReels?
                </p>
                {windowWidth < 768 ? (
                    <>
                        <Slider {...settings}>
                            {features.map((feature) => (
                                <div key={feature.id} className='feature-content'>
                                    <div className='feature-item'>
                                        <img src={feature.image} alt={`feature${feature.id}`} className='feature-image' />
                                        <p className='feature-heading'>{feature.heading}</p>
                                        <p className='feature-description'>{feature.description}</p>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </>
                ) : (
                    <>
                        <div className='feature-list'>
                            {features.map((feature) => (
                                <div key={feature.id} className='feature-content'>
                                    <div className='feature-item'>
                                        <img src={feature.image} alt={`feature${feature.id}`} className='feature-image' />
                                        <p className='feature-heading'>{feature.heading}</p>
                                        <p className='feature-description'>{feature.description}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                )}

            </div>
        </div>
    )
}

export default Features;
